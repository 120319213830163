import React from 'react';
import { NavLink } from 'react-router-dom';
import Logout from './Logout';
import { FileText, ChevronLeft, ChevronRight, ChartColumn, NotebookPen, Pen } from 'lucide-react';

export default function NavbarSidebar({ setIsLoggedIn, isSidebarOpen, toggleSidebar }) {
  return (
    <>
      <nav className="w-full text-black bg-white fixed top-0 left-0 z-50 h-16 flex items-center px-4 shadow-lg">
        <div className="flex items-center space-x-4">
          <img src="/logo.png" alt="Company Logo" className="w-12 h-11" />
          <h1 className="text-xl font-bold">Safety सारथी</h1>
        </div>
      </nav>

      <aside className={`bg-white text-gray-600 fixed top-16 left-0 h-[calc(100vh-4rem)] flex flex-col shadow-lg transition-all duration-300 ease-in-out z-40 ${
        isSidebarOpen ? 'w-64' : 'w-20'
      }`}>
        <div className="flex-grow p-4 space-y-2 overflow-y-auto">
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              `flex items-center space-x-2 p-2 rounded-lg ${
                isActive
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'hover:bg-indigo-50 hover:text-indigo-600'
              }`
            }
          >
            <ChartColumn size={20} />
            {isSidebarOpen && <span>Dashboard</span>}
          </NavLink>
          <NavLink
            to="/surveyOptions"
            className={({ isActive }) =>
              `flex items-center space-x-2 p-2 rounded-lg ${
                isActive
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'hover:bg-indigo-50 hover:text-indigo-600'
              }`
            }
          >
            <FileText size={20} />
            {isSidebarOpen && <span>Survey Options</span>}
          </NavLink>
          <NavLink
            to="/advancedReports"
            className={({ isActive }) =>
              `flex items-center space-x-2 p-2 rounded-lg ${
                isActive
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'hover:bg-indigo-50 hover:text-indigo-600'
              }`
            }
          >
            <NotebookPen size={20} />
            {isSidebarOpen && <span>Advanced Reports</span>}
          </NavLink>
          {/* <NavLink
            to="/forms"
            className={({ isActive }) =>
              `flex items-center space-x-2 p-2 rounded-lg ${
                isActive
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'hover:bg-indigo-50 hover:text-indigo-600'
              }`
            }
          >
            <Pen size={20} />
            {isSidebarOpen && <span>Forms</span>}
          </NavLink> */}
        </div>
        <div className="p-4 border-t border-gray-200">
          <Logout setIsLoggedIn={setIsLoggedIn} isSidebarOpen={isSidebarOpen} />
        </div>
        <button
          onClick={toggleSidebar}
          className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-indigo-500 text-white p-1 rounded-full shadow-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 z-50"
        >
          {isSidebarOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
        </button>
      </aside>
    </>
  );
}