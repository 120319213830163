import React from "react";
import { useLocation } from "react-router-dom";
import CustomerDetails from "./customerDetails";
import Letterhead from "../CommonFormComponents/letterHead/letterHead";
import DownloadButton from "../CommonFormComponents/DownloadButton/downloadButton";

export default function SmokeDetectionSystem() {
  const location = useLocation();
  const { survey } = location.state || {};
  const smokeDetectionSystem = survey?.system;

  if (!smokeDetectionSystem) {
    return <div>No Smoke Detection System data available.</div>;
  }

  const generateTableRows = () => {
    const rows = [
      { label: "Type:", value: smokeDetectionSystem.Type || "N/A" },
      { label: "Alarm Make:", value: smokeDetectionSystem.Alarm_Make || "N/A" },
      { label: "Alarm Model No.:", value: smokeDetectionSystem.Alarm_Model_No || "N/A" },
    ];

    if (smokeDetectionSystem.Type === 'Addressable') {
      rows.push(
        { label: "Alarm No. of Loops:", value: smokeDetectionSystem.Addressable?.Alarm_No_Of_Loops || "N/A" },
        { label: "Is Representative Addressable:", value: smokeDetectionSystem.Addressable?.Is_Rep_Addr || "N/A" },
      );

      if (smokeDetectionSystem.Addressable?.Is_Rep_Addr === 'Yes') {
        rows.push(
          { label: "Number of Repeater panels:", value: smokeDetectionSystem.Addressable?.No_Of_Rep_Panels || "N/A" },
          { label: "No. of Active Devices:", value: smokeDetectionSystem.Addressable?.No_Of_Active || "N/A" },
          { label: "No. of Passive Devices:", value: smokeDetectionSystem.Addressable?.No_Of_Passive || "N/A" },
        );
      }

      rows.push(
        { label: "Representative Model No.:", value: smokeDetectionSystem.Addressable?.Rep_Model_No || "N/A" },
        { label: "Control Module:", value: smokeDetectionSystem.Addressable?.Control_Module || "N/A" },
        { label: "Isolator Module:", value: smokeDetectionSystem.Addressable?.Isolator_Module || "N/A" },
        { label: "Relay Module:", value: smokeDetectionSystem.Addressable?.Relay_Module || "N/A" },
        { label: "Monitor Module:", value: smokeDetectionSystem.Addressable?.Monitor_Module || "N/A" },
        { label: "Zone Interface:", value: smokeDetectionSystem.Addressable?.Zone_Interface || "N/A" },
        { label: "External Power Supply:", value: smokeDetectionSystem.Addressable?.External_Power || "N/A" },
      );
    } else if (smokeDetectionSystem.Type === 'Conventional') {
      rows.push(
        { label: "Alarm Number of Zones:", value: smokeDetectionSystem.Conventional?.Alarm_No_Of_Zones || "N/A" },
      );
    }

    rows.push(
      { label: "Smoke Detectors:", value: smokeDetectionSystem.Smoke_Det || "N/A" },
      { label: "Heat Detectors:", value: smokeDetectionSystem.Heat_Det || "N/A" },
      { label: "Beam Detectors:", value: smokeDetectionSystem.Beam_Det || "N/A" },
      { label: "Multi Detectors:", value: smokeDetectionSystem.Multi_Det || "N/A" },
      { label: "Manual Call Points:", value: smokeDetectionSystem.Manual_Call_Pt || "N/A" },
      { label: "Hooters:", value: smokeDetectionSystem.Hooters || "N/A" },
      { label: "Response Indicators:", value: smokeDetectionSystem.Response_Ind || "N/A" },
      { label: "Hooter Cum Flasher:", value: smokeDetectionSystem.Hooter_Cum_Flasher || "N/A" },
      { label: "Batteries:", value: smokeDetectionSystem.Batteries || "N/A" },
    );

    return rows;
  };

  const generateNewSystemRows = () => {
    const rows = [
      { label: "New Alarm No. of Loops:", value: smokeDetectionSystem.Addressable?.New_Alarm_No_Of_Loops || "N/A" },
      { label: "New Alarm No. of Zones:", value: smokeDetectionSystem.Conventional?.New_Alarm_No_Of_Zones || "N/A" },
      { label: "New No. of Active Devices:", value: smokeDetectionSystem.Addressable?.New_No_Of_Active || "N/A" },
      { label: "New No. of Passive Devices:", value: smokeDetectionSystem.Addressable?.New_No_Of_Passive || "N/A" },
      { label: "New Control Module:", value: smokeDetectionSystem.Addressable?.New_Control_Module || "N/A" },
      { label: "New Isolator Module:", value: smokeDetectionSystem.Addressable?.New_Isolator_Module || "N/A" },
      { label: "New Relay Module:", value: smokeDetectionSystem.Addressable?.New_Relay_Module || "N/A" },
      { label: "New Monitor Module:", value: smokeDetectionSystem.Addressable?.New_Monitor_Module || "N/A" },
      { label: "New Zone Interface:", value: smokeDetectionSystem.Addressable?.New_Zone_Interface || "N/A" },
      { label: "New External Power Supply:", value: smokeDetectionSystem.Addressable?.New_External_Power || "N/A" },
      { label: "New Smoke Detectors:", value: smokeDetectionSystem.New_Smoke_Det || "N/A" },
      { label: "New Heat Detectors:", value: smokeDetectionSystem.New_Heat_Det || "N/A" },
      { label: "New Beam Detectors:", value: smokeDetectionSystem.New_Beam_Det || "N/A" },
      { label: "New Multi Detectors:", value: smokeDetectionSystem.New_Multi_Det || "N/A" },
      { label: "New Manual Call Points:", value: smokeDetectionSystem.New_Manual_Call_Pt || "N/A" },
      { label: "New Hooters:", value: smokeDetectionSystem.New_Hooters || "N/A" },
      { label: "New Response Indicators:", value: smokeDetectionSystem.New_Response_Ind || "N/A" },
      { label: "New Hooter Cum Flasher:", value: smokeDetectionSystem.New_Hooter_Cum_Flasher || "N/A" },
      { label: "New Batteries:", value: smokeDetectionSystem.New_Batteries || "N/A" },
    ];

    return rows;
  };

  const tableRows = generateTableRows();
  const newSystemRows = generateNewSystemRows();
  const isNewSystem = smokeDetectionSystem.New_System === "Yes";

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
      <Letterhead />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
        <h3 className="text-2xl font-bold">Survey Report</h3>
      </div>

      <CustomerDetails survey={survey} />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
        <h3 className="text-2xl font-bold">Smoke Detection System</h3>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg mb-6">
        <table className="w-full bg-white">
          {/* <thead className="bg-gray-200">
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead> */}
          <tbody>
            {tableRows.map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="p-3 text-sm border font-bold">{row.label}</td>
                <td className="p-3 text-sm border">{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {
        isNewSystem && (
            <>
                <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
                  <h3 className="text-2xl font-bold">New Smoke Detection System Recommendation</h3>
                </div>

                <div className="overflow-x-auto shadow-md rounded-lg mb-6">
                  <table className="w-full bg-white">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="p-3 text-sm font-semibold text-left"></th>
                            <th className="p-3 text-sm font-semibold text-left">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {newSystemRows.map((row, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                                <td className="p-3 text-sm border font-bold">{row.label}</td>
                                <td className="p-3 text-sm border">{row.value}</td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
            </>
        )
      }

      <div className="flex justify-center">
        <DownloadButton
          reportId={survey.report_id}
          clientName={survey.customerDetails?.client_name}
          type="advanced_survey"
        />
      </div>
    </div>
  );
}