import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Download } from 'lucide-react';

const DownloadButton = ({ reportId, clientName, type }) => {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);

  const downloadPDF = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/generate/${type}/${reportId}`, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${reportId}_${clientName}.pdf`;

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <button 
      className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      onClick={downloadPDF}
    >
      <Download size={18} className="mr-2" />
      Download PDF
    </button>
  );
};

export default DownloadButton;