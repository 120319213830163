import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const SurveyTypePieChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <div className="bg-white p-4 rounded-lg shadow-lg">No data available for Survey Types</div>;
  }

  const chartData = {
    labels: data.map(item => item.name),
    datasets: [
      {
        data: data.map(item => item.value),
        backgroundColor: [
          '#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#9b59b6', '#34495e', '#1abc9c', '#d35400'
        ],
        borderColor: [
          '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Survey Types Distribution',
        font: {
          size: 20,
          weight: 'bold',
        },
        color: '#2c3e50',
        padding: {
          top: 10,
          bottom: 30
        }
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        titleColor: '#2c3e50',
        bodyColor: '#2c3e50',
        bodyFont: {
          size: 16,
          weight: 'bold'
        },
        padding: 12,
        boxPadding: 8,
        borderColor: '#e2e8f0',
        borderWidth: 1,
        callbacks: {
          title: function() {
            return ''; // Remove the title
          },
          label: function(context) {
            let value = context.parsed;
            let total = context.dataset.data.reduce((acc, data) => acc + data, 0);
            let percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${value} (${percentage}%)`;
          }
        },
        bodyAlign: 'center',
        titleAlign: 'center'
      }
    },
  };

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg" style={{ height: '450px' }}>
      <Doughnut data={chartData} options={options} />
    </div>
  );
};

const EngineerBarChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <div className="bg-white p-4 rounded-lg shadow-lg">No data available for Engineer Chart</div>;
  }

  // Extract all unique keys except 'name' to use as series
  const allKeys = [...new Set(data.flatMap(Object.keys))].filter(key => key !== 'name');

  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: data.map(item => item.name || 'Unknown'),
    },
    yaxis: {
      title: {
        text: 'Number of Surveys'
      },
    },
    legend: {
      position: 'top',
    },
    title: {
      text: 'Surveys by Engineer',
      align: 'center',
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#263238',
      },
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
    tooltip: {
      y: {
        formatter: function(value) {
          return value !== undefined ? value.toString() : '0';
        }
      }
    },
  };

  const series = allKeys.map(key => ({
    name: key,
    data: data.map(item => (typeof item[key] === 'number' ? item[key] : 0))
  }));

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg">
      <ReactApexChart options={chartOptions} series={series} type="bar" height={450} />
    </div>
  );
};

const MonthlySubmissionsChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <div className="bg-white p-4 rounded-lg shadow-lg">No data available for Monthly Submissions</div>;
  }

  const chartOptions = {
    chart: {
      id: 'monthly-submissions',
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    xaxis: {
      categories: data.map(item => item.month || 'Unknown'),
    },
    yaxis: {
      title: {
        text: 'Number of Submissions',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#2c3e50'
        }
      },
    },
    colors: ['#3498db'],
    title: {
      text: 'Monthly Survey Submissions',
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#2c3e50',
      },
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };

  const series = [
    {
      name: 'Submissions',
      data: data.map(item => item.count || 0),
    },
  ];

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg">
      <ReactApexChart options={chartOptions} series={series} type="area" height={350} />
    </div>
  );
};

const BaseSurveyDashboard = () => {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [surveyTypeData, setSurveyTypeData] = useState([]);
  const [engineerChartData, setEngineerChartData] = useState([]);
  const [monthlyChartData, setMonthlyChartData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/dashboard-data/Reports`);
        const { surveyTypeData, engineerChartData, monthlyChartData } = response.data;
        
        setSurveyTypeData(surveyTypeData || []);
        setEngineerChartData(engineerChartData || []);
        setMonthlyChartData(monthlyChartData || []);
      } catch (error) {
        console.error('Failed to fetch dashboard data', error);
        setError('Failed to load dashboard data. Please try again later.');
      }
    };

    fetchData();
  }, [API_URL]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-2">
        <SurveyTypePieChart data={surveyTypeData} />
        <MonthlySubmissionsChart data={monthlyChartData} />
        <div className="col-span-1">
          <EngineerBarChart data={engineerChartData} />
        </div>
    </div>
  );
};

const AdvancedSurveysDashboard = () => {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [surveyTypeData, setSurveyTypeData] = useState([]);
  const [engineerChartData, setEngineerChartData] = useState([]);
  const [monthlyChartData, setMonthlyChartData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/dashboard-data/Advanced_Reports`);
        const { surveyTypeData, engineerChartData, monthlyChartData } = response.data;
        
        setSurveyTypeData(surveyTypeData || []);
        setEngineerChartData(engineerChartData || []);
        setMonthlyChartData(monthlyChartData || []);
      } catch (error) {
        console.error('Failed to fetch dashboard data', error);
        setError('Failed to load dashboard data. Please try again later.');
      }
    };

    fetchData();
  }, [API_URL]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-2">
        <SurveyTypePieChart data={surveyTypeData} />
        <MonthlySubmissionsChart data={monthlyChartData} />
        <div className="col-span-2">
          <EngineerBarChart data={engineerChartData} />
        </div>
    </div>
  );
};

const AMCsDashboard = () => (
  <div className="bg-white p-4 rounded-lg shadow-lg">
    <h2 className="text-xl font-bold mb-4">AMCs Dashboard</h2>
    <p>This is a placeholder for the AMCs Dashboard.</p>
  </div>
);

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('base');

  return (
    <div className="container mx-auto p-4 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Survey Dashboard</h1>
      <div className="mb-6">
        <div className="flex border-b border-gray-200">
          {['base', 'advanced', 'amcs'].map((tab) => (
            <button
              key={tab}
              className={`py-2 px-4 font-medium ${
                activeTab === tab
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab === 'base' ? 'Base Surveys' : tab === 'advanced' ? 'Advanced Surveys' : 'AMCs'}
            </button>
          ))}
        </div>
      </div>
      {activeTab === 'base' && <BaseSurveyDashboard />}
      {activeTab === 'advanced' && <AdvancedSurveysDashboard />}
      {activeTab === 'amcs' && <AMCsDashboard />}
    </div>
  );
};

export default Dashboard;