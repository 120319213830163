import React from "react";
import { useLocation } from "react-router-dom";
import CustomerDetails from "./customerDetails";
import Letterhead from "../CommonFormComponents/letterHead/letterHead";
import DownloadButton from "../CommonFormComponents/DownloadButton/downloadButton";

const FireSprinklerSystem = () => {
  const location = useLocation();
  const { survey } = location.state || {};
  const fireSprinklerSystem = survey.system;

  if (!fireSprinklerSystem) {
    return <div>No Fire Sprinkler System data available.</div>;
  }

  // Define the initial table rows
  const tableRows = [
    { label: "Application Area:", value: fireSprinklerSystem?.App_Area || "N/A" },
    { label: "Length of Area:", value: fireSprinklerSystem?.Length || "N/A" },
    { label: "Width of Area:", value: fireSprinklerSystem?.Width || "N/A" },
    { label: "Design of Sprinkler System as per NBC:", value: fireSprinklerSystem?.Design_As_Per_Nbc || "N/A" },
    { label: "Reason:", value: fireSprinklerSystem?.Reason || "N/A" },
    { label: "No. of Sprinkler Risers:", value: fireSprinklerSystem?.No_Of_Risers || "N/A" },
    { label: "Size of Sprinkler Riser:", value: fireSprinklerSystem?.Size_Of_Riser || "N/A" },
    { label: "No. of Total Sprinklers:", value: fireSprinklerSystem?.No_Of_Total_Sprinklers || "N/A" },
    { label: "Alarm Valve Condition:", value: fireSprinklerSystem?.Alarm_Valve_Cond || "N/A" },
    { label: "No. of Valves:", value: fireSprinklerSystem?.No_Of_Valves || "N/A" },
    { label: "System Pressurized Status:", value: fireSprinklerSystem?.System_Pressure_Status || "N/A" },
    { label: "Pressure:", value: fireSprinklerSystem?.Pressure || "N/A" },
    { label: "System (Automatic/Manual):", value: fireSprinklerSystem?.System_Auto || "N/A" },
    { label: "Integration with Staircase Pressurization System:", value: fireSprinklerSystem?.Int_With_Stair || "N/A" },
    { label: "Integration with Basement Pressurization System:", value: fireSprinklerSystem?.Int_With_Base || "N/A" }
  ];


  // Define column headers based on the new system data
  const headers = ["", "Details"];

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
      <Letterhead />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
        <h3 className="text-2xl font-bold">Survey Report</h3>
      </div>

      <CustomerDetails survey={survey} />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
        <h3 className="text-2xl font-bold">Fire Sprinkler System</h3>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg mb-6">
        <table className="w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableRows.map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="p-3 text-sm border font-bold">{row.label}</td>
                <td className="p-3 text-sm border">{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center">
        <DownloadButton
          reportId={survey.report_id}
          clientName={survey.customerDetails?.client_name}
          type="advanced_survey"
        />
      </div>
    </div>
  );
};

export default FireSprinklerSystem;