import React from 'react';
import styles from './letterHead.module.css';
// import logo from 'logo.png'; // Adjust the path to your logo image

function Letterhead() {
  return (
    <div className={styles.header}>
      <img className={styles.headerImg} src="logo.png" alt="NFPA India Pvt. Ltd. Logo" />
      <div className={styles.letterHead}>
        <div className={styles.headerTitle}>NFPA INDIA PVT. LTD.</div>
        <p className={`${styles.red} ${styles.bold}`}>
          GOVERNMENT APPROVED CLASS-A LICENSED AGENCY
        </p>
        <p className={styles.letterHeadP}>Building No. 3, Pard B, Sai Nagari, Pune - Alandi Road, Wadamukhwadi, PIN Code - 412105</p>
        <p className={styles.letterHeadP}>
          Email Address -{' '}
          <span className={styles.blue}>sagar.nfpa@gmail.com / aditya@nfpa.in</span>
        </p>
        <p className={styles.letterHeadP}>Contact - 9403732325; 8956051385</p>
        <p className={styles.letterHeadP}>GSTIN - 27AAICN2889B1ZA</p>
      </div>
    </div>
  );
}

export default Letterhead;
