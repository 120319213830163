// import React, { useState } from 'react';
// import NavbarSidebar from './NavbarSidebar';
// import { Outlet } from 'react-router-dom';

// export default function Layout({ children, setIsLoggedIn }) {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     <div className="flex flex-col min-h-screen">
//       <NavbarSidebar 
//         setIsLoggedIn={setIsLoggedIn} 
//         isSidebarOpen={isSidebarOpen} 
//         toggleSidebar={toggleSidebar}
//       />
//       <div className="flex flex-1 pt-16">
//         <main className={`flex-1 p-4 transition-all duration-300 ease-in-out ${
//           isSidebarOpen ? 'ml-64' : 'ml-20'
//         }`}>
//           {/* {children} */}
//           <Outlet />
//         </main>
//       </div>
//     </div>
//   );
// }

import React, { useState } from 'react';
import NavbarSidebar from './NavbarSidebar';
import { Outlet } from 'react-router-dom';

export default function Layout({ setIsLoggedIn }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <NavbarSidebar 
        setIsLoggedIn={setIsLoggedIn} 
        isSidebarOpen={isSidebarOpen} 
        toggleSidebar={toggleSidebar}
      />
      <div className="flex flex-1 pt-16">
        <div className={`fixed inset-y-0 left-0 z-30 transition-all duration-300 ease-in-out ${
          isSidebarOpen ? 'w-64' : 'w-20'
        }`}>
          {/* This div represents the space taken by the sidebar */}
        </div>
        <main className={`flex-1 p-4 transition-all duration-300 ease-in-out overflow-x-hidden ${
          isSidebarOpen ? 'ml-64' : 'ml-20'
        }`}>
          <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 rounded-lg relative z-10">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}