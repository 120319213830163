import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import PrivacyPolicy from './components/PrivacyPolicy';
import Layout from './components/Layout';
import Login from './components/Login';
import SurveyReports from './components/SurveyReports';
import AdvancedReports from './components/AdvancedReports';
import BasicSurvey from './components/SurveyForms/BasicSurvey/BasicSurvey';
import DetailedSurvey from './components/SurveyForms/DetailedSurvey/detailedSurvey';
import FormB from './components/SurveyForms/FormB/formB';
import Dashboard from './components/Dashboard';
import FireAlarmSystem from './components/AdvancedReports/FireAlarmSystem';
import FireSafetyDoors from './components/AdvancedReports/FireSafetyDoors';
import GasLeakDetectionSystem from './components/AdvancedReports/GasLeakDetectionSystem';
import FireSprinklerSystem from './components/AdvancedReports/FireSprinklerSystem';
import KitchenSuppressionSystem from './components/AdvancedReports/KitchenSuppressionSystem';
import RoomSuppressionSystem from './components/AdvancedReports/RoomSuppressionSystem';
import SmokeDetectionSystem from './components/AdvancedReports/SmokeDetectionSystem';
import TubeSuppressionSystem from './components/AdvancedReports/TubeSuppressionSystem';

// import Forms from './components/Forms/forms';
// import ClientDetails from './components/Forms/client-details';
// import BranchDetails from './components/Forms/branch-details';

// import BasicSurveyForm from './components/Forms/BaseSurveys/BasicSurveyForm';
// import DetailedSurveyForm from './components/Forms/BaseSurveys/DetailedSurveyForm';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        
        <Route
          element={
            isLoggedIn ? (
              <Layout setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        >
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/surveyOptions" element={<SurveyReports />} />
            <Route path="/basicSurvey" element={<BasicSurvey />} />
            <Route path="/detailedSurvey" element={<DetailedSurvey />} />
            <Route path="/formB" element={<FormB />} />
            <Route path="/advancedReports" element={<AdvancedReports />} />
            <Route path="/Fire_Alarm_System" element={<FireAlarmSystem />} />
            <Route path="/Fire_Safety_Doors" element={<FireSafetyDoors />} />
            <Route path="/Gas_Leak_Detectors" element={<GasLeakDetectionSystem />} />
            <Route path="/Sprinkler_System" element={<FireSprinklerSystem />} />
            <Route path="/Kitchen_Suppression_System" element={<KitchenSuppressionSystem />} />
            <Route path="/Room_Suppression_System" element={<RoomSuppressionSystem />} />
            <Route path="/Smoke_Detection_System" element={<SmokeDetectionSystem />} />
            <Route path="/Tube_Based_Suppression_System" element={<TubeSuppressionSystem />} />
            <Route path="/dashboard" element={<Dashboard />} />

            {/* <Route path="/forms" element={<Forms />} />
            <Route path="/forms/:client_id" element={<ClientDetails />} />
            <Route path="/forms/:client_id/branch/:branch_id" element={<BranchDetails />} />

            <Route path="/survey/:client_id/:branch_id/basic_survey" element={<BasicSurveyForm />} />
            <Route path="/survey/:client_id/:branch_id/detailed_survey" element={<DetailedSurveyForm />} /> */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;