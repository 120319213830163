import React from "react";
import { useLocation } from 'react-router-dom';
import Letterhead from "../../CommonFormComponents/letterHead/letterHead";
import DownloadButton from "../../CommonFormComponents/DownloadButton/downloadButton";

const DetailedSurvey = () => {
    const location = useLocation();
    const { survey } = location.state || {};

    if (!survey) {
        return null;
    }

    return (
        
        <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
            <Letterhead />

            <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
                <h3 className="text-2xl font-bold">Fire Audit Report</h3>
            </div>

            <div className="overflow-x-auto shadow-md rounded-lg mb-6">
                <table className="w-full bg-white">
                    <tbody>
                        <tr>
                            <td className="p-3 text-sm border w-1/2">Name of Client: {survey.customerDetails?.client_name ?? 'NA'}</td>
                            <td className="p-3 text-sm border">Contact Person 1: {survey.customerDetails?.contact_person_1 ?? 'NA'}</td>
                            <td className="p-3 text-sm border">Date: {survey.date}</td>
                        </tr>
                        <tr>
                            <td className="p-3 text-sm border w-1/2">Address: {survey.customerDetails?.address ?? 'NA'}</td>
                            <td className="p-3 text-sm border">Contact Person 2: {survey.customerDetails?.contact_person_2 ?? 'NA'}</td>
                            <td className="p-3 text-sm border">Report No: {survey.report_id}</td>
                        </tr>
                        <tr>
                            <td className="p-3 text-sm border" colSpan={3}>Building: {survey.customerDetails?.building ?? 'NA'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {survey.fireHydrant && survey.fireHydrant.length > 0 && (
                <>
                    <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
                        <h3 className="text-2xl font-bold">Fire Hydrant System</h3>
                    </div>

                    <div className="overflow-x-auto shadow-md rounded-lg mb-6">
                        <table className="w-full bg-white">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-3 text-sm font-semibold text-left w-1/10" rowSpan={2}>Location</th>
                                    <th className="p-3 text-sm font-semibold text-center" colSpan={6}>Hydrant</th>
                                    <th className="p-3 text-sm font-semibold text-center" colSpan={7}>Hose Box</th>
                                </tr>
                                <tr className="bg-gray-200">
                                    <th className="p-3 text-sm font-semibold text-left">Hydrant Coupling</th>
                                    <th className="p-3 text-sm font-semibold text-left">Latch</th>
                                    <th className="p-3 text-sm font-semibold text-left">Gland Nut</th>
                                    <th className="p-3 text-sm font-semibold text-left">Hydrant Cap</th>
                                    <th className="p-3 text-sm font-semibold text-left">Coupling Washer</th>
                                    <th className="p-3 text-sm font-semibold text-left">Wheel / Wheel Nut</th>
                                    <th className="p-3 text-sm font-semibold text-left">Short Branch Pipe</th>
                                    <th className="p-3 text-sm font-semibold text-left">Rubber Bidding</th>
                                    <th className="p-3 text-sm font-semibold text-left">Key</th>
                                    <th className="p-3 text-sm font-semibold text-left">Key Glass</th>
                                    <th className="p-3 text-sm font-semibold text-left">Hose Box Glass</th>
                                    <th className="p-3 text-sm font-semibold text-left">Hose Condition</th>
                                    <th className="p-3 text-sm font-semibold text-left">Hammer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {survey.fireHydrant.map((hydrant, index) => (
                                    <tr key={index} className="hover:bg-gray-50">
                                        <td className="p-3 text-sm border">{hydrant.Location ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Hydrant_Coupling ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Latch ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Gland_Nut ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Hydrant_Cap ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Coupling_Washer ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Wheel_Wheel_Nut ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Short_Branch_Pipe ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Rubber_Bidding ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Key ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Key_Glass ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Hose_Box_Glass ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Hose_Condition ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hydrant.Hammer ?? 'NA'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            {survey.fireAlarm && survey.fireAlarm.length > 0 && (
                <>
                    <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
                        <h3 className="text-2xl font-bold">Fire HOSE REEL DRUM & FIRE ALARM System</h3>
                    </div>

                    <div className="overflow-x-auto shadow-md rounded-lg mb-6">
                        <table className="w-full bg-white">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-3 text-sm font-semibold text-left w-1/10" rowSpan={2}>Location</th>
                                    <th className="p-3 text-sm font-semibold text-center" colSpan={4}>HOSE REEL DRUM</th>
                                    <th className="p-3 text-sm font-semibold text-center" colSpan={5}>FIRE ALARM SYSTEM</th>
                                </tr>
                                <tr className="bg-gray-200">
                                    <th className="p-3 text-sm font-semibold text-left">Rubber Hose Condition</th>
                                    <th className="p-3 text-sm font-semibold text-left">Operating Valve Condition</th>
                                    <th className="p-3 text-sm font-semibold text-left">Shut Off Nozzle</th>
                                    <th className="p-3 text-sm font-semibold text-left">Drum Movement</th>
                                    <th className="p-3 text-sm font-semibold text-left">Panel Condition</th>
                                    <th className="p-3 text-sm font-semibold text-left">Smoke / Heat Detector</th>
                                    <th className="p-3 text-sm font-semibold text-left">Beam Detector</th>
                                    <th className="p-3 text-sm font-semibold text-left">MCP</th>
                                    <th className="p-3 text-sm font-semibold text-left">Hooter</th>
                                </tr>
                            </thead>
                            <tbody>
                                {survey.fireAlarm.map((hose_alarm, index) => (
                                    <tr key={index} className="hover:bg-gray-50">
                                        <td className="p-3 text-sm border">{hose_alarm.Location ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hose_alarm.Rubber_Hose_Condition ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hose_alarm.Operating_Valve_Condition ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hose_alarm.Shut_Off_Nozzle ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hose_alarm.Drum_Movement ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hose_alarm.Panel_Condition ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hose_alarm.Smoke_Heat_Detector ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hose_alarm.Beam_Detector ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hose_alarm.MCP ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{hose_alarm.Hooter ?? 'NA'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            {survey.firePump && survey.firePump.length > 0 && (
                <>
                    <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
                        <h3 className="text-2xl font-bold">Fire Pump</h3>
                    </div>

                    <div className="overflow-x-auto shadow-md rounded-lg mb-6">
                        <table className="w-full bg-white">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-3 text-sm font-semibold text-left w-1/10" rowSpan={2}>Sr. No.</th>
                                    <th className="p-3 text-sm font-semibold text-center" rowSpan={2}>Pump</th>
                                    <th className="p-3 text-sm font-semibold text-center" colSpan={11}>Fire Fighting Pump</th>
                                </tr>
                                <tr className="bg-gray-200">
                                    <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                                    <th className="p-3 text-sm font-semibold text-left">Gland</th>
                                    <th className="p-3 text-sm font-semibold text-left">Motor Fan</th>
                                    <th className="p-3 text-sm font-semibold text-left">Cable connection</th>
                                    <th className="p-3 text-sm font-semibold text-left">Foundation</th>
                                    <th className="p-3 text-sm font-semibold text-left">Movement</th>
                                    <th className="p-3 text-sm font-semibold text-left">Winding</th>
                                    <th className="p-3 text-sm font-semibold text-left">Valve</th>
                                    <th className="p-3 text-sm font-semibold text-left">Pressure Switch</th>
                                    <th className="p-3 text-sm font-semibold text-left">Pressure Gauge</th>
                                    <th className="p-3 text-sm font-semibold text-left">Panel Condition</th>
                                </tr>
                            </thead>
                            <tbody>
                                {survey.firePump.map((pump, index) => (
                                    <tr key={index} className="hover:bg-gray-50">
                                        <td className="p-3 text-sm border">{index + 1}</td>
                                        <td className="p-3 text-sm border">{pump.Pump ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Capacity ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Gland ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Motor_Fan ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Cable_Connection ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Foundation ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Movement ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Winding ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Valve ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Pressure_Switch ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Pressure_Gauge ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{pump.Panel_Condition ?? 'NA'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            {survey.boosterPump && (
                <div className="overflow-x-auto shadow-md rounded-lg mb-6">
                    <table className="w-full bg-white">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="p-3 text-sm font-semibold text-left w-1/10" rowSpan={2}>Booster Pump</th>
                                <th className="p-3 text-sm font-semibold text-left">Gland</th>
                                <th className="p-3 text-sm font-semibold text-left">Motor Fan</th>
                                <th className="p-3 text-sm font-semibold text-left">Motor connection</th>
                                <th className="p-3 text-sm font-semibold text-left">Foundation</th>
                                <th className="p-3 text-sm font-semibold text-left">Valve</th>
                                <th className="p-3 text-sm font-semibold text-left">Cover</th>
                                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="hover:bg-gray-50">
                                <td className="p-3 text-sm border">{survey.boosterPump.Gland ?? 'NA'}</td>
                                <td className="p-3 text-sm border">{survey.boosterPump.Motor_Fan ?? 'NA'}</td>
                                <td className="p-3 text-sm border">{survey.boosterPump.Motor_Connection ?? 'NA'}</td>
                                <td className="p-3 text-sm border">{survey.boosterPump.Foundation ?? 'NA'}</td>
                                <td className="p-3 text-sm border">{survey.boosterPump.Valve ?? 'NA'}</td>
                                <td className="p-3 text-sm border">{survey.boosterPump.Cover ?? 'NA'}</td>
                                <td className="p-3 text-sm border">{survey.boosterPump.Remarks ?? 'NA'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {survey.fireExtinguishers && survey.fireExtinguishers.length > 0 && (
                <>
                    <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
                        <h3 className="text-2xl font-bold">Fire Extinguishers</h3>
                    </div>

                    <div className="overflow-x-auto shadow-md rounded-lg mb-6">
                        <table className="w-full bg-white">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-3 text-sm font-semibold text-left">Sr. No</th>
                                    <th className="p-3 text-sm font-semibold text-left">Location</th>
                                    <th className="p-3 text-sm font-semibold text-left">Type</th>
                                    <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                                    <th className="p-3 text-sm font-semibold text-left">HPT Due</th>
                                    <th className="p-3 text-sm font-semibold text-left">HPT Done</th>
                                    <th className="p-3 text-sm font-semibold text-left">Refilling Due</th>
                                    <th className="p-3 text-sm font-semibold text-left">Refilling Done</th>
                                    <th className="p-3 text-sm font-semibold text-left">Pressure Gauge</th>
                                    <th className="p-3 text-sm font-semibold text-left">Hose Pipe</th>
                                    <th className="p-3 text-sm font-semibold text-left">Nozzle Horn</th>
                                    <th className="p-3 text-sm font-semibold text-left">Safety Pin</th>
                                    <th className="p-3 text-sm font-semibold text-left">Body Sticker</th>
                                    <th className="p-3 text-sm font-semibold text-left">Body Belt</th>
                                    <th className="p-3 text-sm font-semibold text-left">Valve</th>
                                    <th className="p-3 text-sm font-semibold text-left">Body Condition</th>
                                </tr>
                            </thead>
                            <tbody>
                                {survey.fireExtinguishers.map((extinguisher, index) => (
                                    <tr key={index} className="hover:bg-gray-50">
                                        <td className="p-3 text-sm border">{index + 1}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Location ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Type ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Capacity ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.HPT_Due ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.HPT_Done ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Refilling_Due ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Refilling_Done ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Pressure_Gauge ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Hose_Pipe ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Nozzle_Horn ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Safety_Pin ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Body_Sticker ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Body_Belt ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Valve ?? 'NA'}</td>
                                        <td className="p-3 text-sm border">{extinguisher.Body_Condition ?? 'NA'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg italic mb-6">
                        Remarks: {survey.remarks || "NA"}
                    </div>
                </>
            )}

            <div className="flex justify-center mt-8">
                <DownloadButton 
                    reportId={survey.report_id} 
                    clientName={survey.customerDetails.client_name} 
                    type="detailed_survey" 
                />
            </div>
        </div>
    );
}

export default DetailedSurvey;