import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ChevronLeft, ChevronRight, Search, Calendar, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const surveyTypes = [
    'All',
    'Fire_Alarm_System',
    'Fire_Safety_Doors',
    'Sprinkler_System',
    'Gas_Leak_Detectors',
    'Kitchen_Suppression_System',
    'Room_Suppression_System',
    'Smoke_Detection_System',
    'Tube_Based_Suppression_System'
];

export default function AdvancedReports() {
    const [API_URL] = useState(process.env.REACT_APP_API_URL);
    const [surveys, setSurveys] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState('all');
    const [startFilterDate, setStartFilterDate] = useState('');
    const [endFilterDate, setEndFilterDate] = useState('');
    const [filteredSurveys, setFilteredSurveys] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalReports, setTotalReports] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const formatButtonText = (text) => {
        return text
            .replace(/_/g, ' ')
            .replace(/\b\w/g, l => l.toUpperCase());
    };

    useEffect(() => {
        fetchSurveys(selectedSurvey);
    }, [selectedSurvey, currentPage, startFilterDate, endFilterDate, searchQuery]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentPage]);

    const formatDateForServer = (inputDate) => {
        const [year, month, day] = inputDate.split('-');
        return `${day}-${month}-${year}`;
    };

    const fetchSurveys = async (surveyType) => {
        const token = localStorage.getItem('token');
        setIsLoading(true);

        const params = {
            surveysPerPage: 10,
            pageNumber: currentPage,
            type: surveyType !== 'all' ? surveyType : undefined,
            startDate: startFilterDate ? formatDateForServer(startFilterDate) : undefined,
            endDate: endFilterDate ? formatDateForServer(endFilterDate) : undefined,
            searchQuery: searchQuery ? searchQuery : undefined,
        };

        try {
            const response = await axios.get(`${API_URL}/api/reports/advanced_survey`, {
                headers: {
                    Authorization: `${token}`,
                },
                params,
            });

            setSurveys(response.data.reports);
            setTotalPages(response.data.totalPages);
            setTotalReports(response.data.totalReports);
            setFilteredSurveys(response.data.reports);

        } catch (error) {
            if (error.response && error.response.status === 404) {
                setSurveys([]);
                setFilteredSurveys([]);
                setTotalPages(0);
                setTotalReports(0);
            } else if ((error.response && error.response.status === 403) || (error.response && error.response.status === 400)) {
                alert('Session expired. You need to log in again.');
                setTimeout(() => {
                    localStorage.removeItem('token');
                    navigate('/');
                }, 3000);
            } else {
                console.error('Error fetching surveys:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSurveyTypeClick = (surveyType) => {
        setSelectedSurvey(surveyType);
        setCurrentPage(1);
    };

    const handleStartDateChange = (e) => {
        const inputDate = e.target.value;
        const today = new Date().toISOString().split('T')[0];
        
        if (inputDate <= today) {
            setStartFilterDate(inputDate);

            if (inputDate && endFilterDate && new Date(inputDate) > new Date(endFilterDate)) {
                setEndFilterDate(inputDate);
            }

            setCurrentPage(1);
        }
    };

    const handleEndDateChange = (e) => {
        const inputDate = e.target.value;
        setEndFilterDate(inputDate);
        setCurrentPage(1);
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        if (/^[a-zA-Z\s]*$/.test(value)) {
            setSearchQuery(value);
        }
    };

    const clearFilter = () => {
        setStartFilterDate('');
        setEndFilterDate('');
        setSearchQuery('');
        setCurrentPage(1);
    };

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        let startPage = Math.max(currentPage - 2, 1);
        let endPage = Math.min(startPage + 4, totalPages);

        if (endPage - startPage < 4) {
            startPage = Math.max(endPage - 4, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    const handleSurveySelect = (reportId) => {
        const selectedSurveyData = surveys.find(survey => survey.report_id === reportId);

        if (selectedSurveyData && selectedSurveyData.type) {
            const surveyType = selectedSurveyData.type;
            navigate(`/${surveyType}`, { state: { survey: selectedSurveyData } });
        } else {
            console.error('Survey type is missing or invalid for the selected report.');
        }
    };

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen p-8"
        >
            <div className="max-w-7xl mx-auto">
                <motion.h1 
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="text-4xl font-bold text-center mb-12 text-indigo-900"
                >
                    Advanced Survey Reports
                </motion.h1>

                <motion.div 
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    className="bg-white rounded-xl shadow-lg p-6 mb-8"
                >
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
                        {surveyTypes.map((type, index) => (
                            <motion.button
                                key={type}
                                onClick={() => handleSurveyTypeClick(type)}
                                className={`px-4 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                                    selectedSurvey === type
                                        ? 'bg-indigo-600 text-white shadow-md'
                                        : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                                }`}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2, delay: index * 0.05 }}
                            >
                                {formatButtonText(type)}
                            </motion.button>
                        ))}
                    </div>

                    <div className="flex flex-wrap items-center justify-between gap-4">
                        <div className="flex flex-wrap items-center gap-4">
                            <motion.div 
                                className="relative"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <input
                                    type="date"
                                    value={startFilterDate}
                                    onChange={handleStartDateChange}
                                    className="border rounded-lg px-4 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    max={new Date().toISOString().split('T')[0]}
                                />
                                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                            </motion.div>
                            <motion.div 
                                className="relative"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <input
                                    type="date"
                                    value={endFilterDate}
                                    onChange={handleEndDateChange}
                                    className="border rounded-lg px-4 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    max={new Date().toISOString().split('T')[0]}
                                    min={startFilterDate}
                                />
                                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                            </motion.div>
                            <motion.div 
                                className="relative flex-grow"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <input
                                    type="text"
                                    placeholder="Search by client or engineer name"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="w-full border rounded-lg px-4 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                />
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                            </motion.div>
                            <motion.button 
                                onClick={clearFilter}
                                className="px-4 py-2 bg-red-100 text-red-600 rounded-lg hover:bg-red-200 transition-colors duration-300 flex items-center"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <X size={18} className="mr-2" />
                                Clear Filter
                            </motion.button>
                        </div>
                        <motion.p 
                            className="text-indigo-600 font-semibold"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                        >
                            Total Reports: {totalReports}
                        </motion.p>
                    </div>
                </motion.div>

                {isLoading ? (
                    <div className="space-y-4">
                        {[...Array(10)].map((_, index) => (
                            <motion.div 
                                key={index} 
                                className="h-20 bg-gray-200 rounded-lg"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: index * 0.05 }}
                            />
                        ))}
                    </div>
                ) : (
                    <AnimatePresence>
                        <motion.div className="space-y-4">
                            {filteredSurveys.map((survey, index) => (
                                <motion.div 
                                    key={survey.report_id} 
                                    onClick={() => handleSurveySelect(survey.report_id)}
                                    className="bg-white rounded-lg shadow-md p-6 cursor-pointer transition-all duration-300"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.3, delay: index * 0.05 }}
                                    whileHover={{ 
                                        scale: 1.02, 
                                        boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                                    }}
                                >
                                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                        <div>
                                            <h3 className="text-lg font-semibold text-indigo-900">{survey.customerDetails?.client_name ?? 'Unknown Client'}</h3>
                                            <p className="text-gray-600">Report ID: {survey.report_id}</p>
                                        </div>
                                        <div>
                                            <p className="text-gray-600">Date: {survey.date}</p>
                                            <p className="text-gray-600">Type: {survey.type?.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) ?? 'Unknown'}</p>
                                        </div>
                                        <div>
                                            <p className="text-gray-600">Supervisor: {survey.engineerName ?? 'No supervisor'}</p>
                                            <div className="mt-2">
                                                <span className="text-sm font-semibold text-gray-700">Products Surveyed:</span>
                                                <div className="flex flex-wrap gap-2 mt-1">
                                                    {['fireExtinguishers', 'fireHydrant', 'fireAlarm', 'firePump', 'boosterPump', 'passiveProtection'].map((product) => (
                                                        survey[product] && (
                                                            <span key={product} className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                                                                {product.split(/(?=[A-Z])/).join(' ').replace(/\b\w/g, l => l.to)}
                                                            </span>
                                                        )
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            ))}
                        </motion.div>
                    </AnimatePresence>
                )}

                <motion.div 
                    className="flex justify-center items-center space-x-2 mt-8"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    <motion.button
                        onClick={goToPreviousPage}
                        disabled={currentPage === 1}
                        className={`px-4 py-2 rounded-lg flex items-center transition-colors duration-300 ${
                            currentPage === 1 
                                ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                                : 'bg-indigo-100 text-indigo-800 hover:bg-indigo-200'
                        }`}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <ChevronLeft size={18} className="mr-1" />
                        Previous
                    </motion.button>
                    {getPageNumbers().map((pageNumber) => (
                        <motion.button
                            key={pageNumber}
                            onClick={() => setCurrentPage(pageNumber)}
                            className={`w-10 h-10 flex items-center justify-center rounded-lg transition-colors duration-300 ${
                                currentPage === pageNumber
                                    ? 'bg-indigo-600 text-white'
                                    : 'bg-indigo-100 text-indigo-800 hover:bg-indigo-200'
                            }`}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            aria-label={`Page ${pageNumber}`}
                            aria-current={currentPage === pageNumber ? 'page' : undefined}
                        >
                            {pageNumber}
                        </motion.button>
                    ))}
                    <motion.button
                        onClick={goToNextPage}
                        disabled={currentPage === totalPages}
                        className={`px-4 py-2 rounded-lg flex items-center transition-colors duration-300 ${
                            currentPage === totalPages 
                                ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                                : 'bg-indigo-100 text-indigo-800 hover:bg-indigo-200'
                        }`}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        Next
                        <ChevronRight size={18} className="ml-1" />
                    </motion.button>
                </motion.div>
            </div>
        </motion.div>
    );
}