import React from "react";
import { useLocation } from 'react-router-dom';
import Letterhead from "../../CommonFormComponents/letterHead/letterHead";
import DownloadButton from "../../CommonFormComponents/DownloadButton/downloadButton";

const BasicSurvey = () => {

  const location = useLocation();
  const { survey } = location.state || {};

  if (!survey) {
    return null;
  }

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
          <Letterhead />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105 z-10">
        <h3 className="text-2xl font-bold">Survey Report</h3>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg mb-6">
        <table className="w-full bg-white">
          <tbody>
            <tr>
              <td className="p-3 text-sm border">Name of Client: {survey.customerDetails.client_name ?? 'NA'}</td>
              <td className="p-3 text-sm border">Contact Person 1: {survey.customerDetails.contact_person_1 ?? 'NA'}</td>
              <td className="p-3 text-sm border">Date: {survey.date}</td>
            </tr>
            <tr>
              <td className="p-3 text-sm border">Address: {survey.customerDetails.address ?? 'NA'}</td>
              <td className="p-3 text-sm border">Contact Person 2: {survey.customerDetails.contact_person_2 ?? 'NA'}</td>
              <td className="p-3 text-sm border">Report No: {survey.report_id}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {survey.fireExtinguishers && survey.fireExtinguishers.length > 0 && (
        <>
          <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
            <h3 className="text-2xl font-bold">Fire Extinguishers</h3>
          </div>

          <div className="overflow-x-auto shadow-md rounded-lg mb-6">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-200">
                  <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                  <th className="p-3 text-sm font-semibold text-left">Type</th>
                  <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                  <th className="p-3 text-sm font-semibold text-left">Quantity</th>
                  <th className="p-3 text-sm font-semibold text-left">Make</th>
                  <th className="p-3 text-sm font-semibold text-left">Last Refilled Date</th>
                  <th className="p-3 text-sm font-semibold text-left">Next Refilling Date</th>
                  <th className="p-3 text-sm font-semibold text-left">Last HPT Date</th>
                  <th className="p-3 text-sm font-semibold text-left">Next HPT Date</th>
                  <th className="p-3 text-sm font-semibold text-left">Manufacturing Date</th>
                  <th className="p-3 text-sm font-semibold text-left">Last Maintained By</th>
                  <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {survey.fireExtinguishers.map((extinguisher, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="p-3 text-sm border">{index + 1}</td>
                    <td className="p-3 text-sm border">{extinguisher.Type ?? 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Capacity ?? 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Quantity ?? 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Make ?? 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Last_Refilled_Date ?? 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Next_Refilling_Date ?? 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Last_Hpt_Date ?? 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Next_Hpt_Date ?? 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Manufacturing_Date ?? 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Last_Maintained_By ?? 'NA'}</td>
                    <td className="p-3 text-sm border">{extinguisher.Remarks ?? 'NA'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
        {survey.fireHydrant ? (
  <>
    <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
      <h3 className="text-2xl font-bold">Fire Hydrant System</h3>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-2/5" rowSpan={2}>Single Headed</td>
              <td className="p-3 text-sm border w-3/10">Internal</td>
              <td className="p-3 text-sm border">{survey.fireHydrant.Single_Headed?.Internal ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-3/10">External</td>
              <td className="p-3 text-sm border">{survey.fireHydrant.Single_Headed?.External ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-2/5" rowSpan={2}>Double Headed</td>
              <td className="p-3 text-sm border w-3/10">Internal</td>
              <td className="p-3 text-sm border">{survey.fireHydrant.Double_Headed?.Internal ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-3/10">External</td>
              <td className="p-3 text-sm border">{survey.fireHydrant.Double_Headed?.External ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-2/5" rowSpan={2}>Single Door Hose Box</td>
              <td className="p-3 text-sm border w-3/10">Internal</td>
              <td className="p-3 text-sm border">{survey.fireHydrant.Single_Door_Hose_Box?.Internal ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-3/10">External</td>
              <td className="p-3 text-sm border">{survey.fireHydrant.Single_Door_Hose_Box?.External ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-2/5" rowSpan={2}>Double Door Hose Box</td>
              <td className="p-3 text-sm border w-3/10">Internal</td>
              <td className="p-3 text-sm border">{survey.fireHydrant.Double_Door_Hose_Box?.Internal ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-3/10">External</td>
              <td className="p-3 text-sm border">{survey.fireHydrant.Double_Door_Hose_Box?.External ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-2/5" rowSpan={2}>2 - Way Inlet</td>
              <td className="p-3 text-sm border" rowSpan={2}>{(survey.fireHydrant?.Two_Way_Inlet || survey.fireHydrant?.Two_Way_Inlet === true  ? 'Yes' : 'NA')}</td>
            </tr>
            <tr className="hover:bg-gray-50"></tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-2/5" rowSpan={2}>3 - Way Inlet</td>
              <td className="p-3 text-sm border">{(survey.fireHydrant?.Three_Way_Inlet ?? 'NA')}</td>
            </tr>
            <tr className="hover:bg-gray-50"></tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-2/5" rowSpan={2}>4 - Way Inlet</td>
              <td className="p-3 text-sm border">{(survey.fireHydrant?.Four_Way_Inlet ?? 'NA')}</td>
            </tr>
            <tr className="hover:bg-gray-50"></tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-2/5" rowSpan={2}>Hose Reel Drum</td>
              <td className="p-3 text-sm border">{survey.fireHydrant?.Hose_Reel_Drum ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50"></tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="bg-gray-100 p-4 rounded-lg italic mb-6">
      Remarks: {survey.fireHydrant?.Remarks ?? 'NA'}
    </div>
  </>
) : null}

{survey.fireAlarm ? (
  <>
    <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
      <h3 className="text-2xl font-bold">FIRE ALARM & DETECTION SYSTEM</h3>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
      <div className="col-span-2 overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-1/5" rowSpan={2}>Fire Alarm Panel</td>
              <td className="p-3 text-sm border w-1/5">Addressable</td>
              <td className="p-3 text-sm border">{(survey.fireAlarm.Fire_Alarm_Panel?.Addressable ?? 'NA')}</td>
              <td className="p-3 text-sm border w-1/5">No. of Loops</td>
              <td className="p-3 text-sm border">{survey.fireAlarm.Fire_Alarm_Panel?.No_Of_Loops ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-1/5">Conventional</td>
              <td className="p-3 text-sm border">{(survey.fireAlarm.Fire_Alarm_Panel?.Conventional ?? 'NA')}</td>
              <td className="p-3 text-sm border w-1/5">No. of Zones</td>
              <td className="p-3 text-sm border">{survey.fireAlarm.Fire_Alarm_Panel?.No_Of_Zones ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-2/5">Make</td>
              <td className="p-3 text-sm border">{survey.fireAlarm.Fire_Alarm_Panel?.Make ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
            <td className="p-3 text-sm border w-2/5">Model No.</td>
            <td className="p-3 text-sm border">{survey.fireAlarm.Fire_Alarm_Panel?.Model_No ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div className="col-span-2 overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-1/5" rowSpan={2}>Repeater Panel</td>
              <td className="p-3 text-sm border w-1/5">Addressable</td>
              <td className="p-3 text-sm border">{(survey.fireAlarm.Repeater_Panel?.Addressable ?? 'NA')}</td>
              <td className="p-3 text-sm border w-1/5">No. of Loops</td>
              <td className="p-3 text-sm border">{survey.fireAlarm.Repeater_Panel?.No_Of_Loops ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-1/5">Conventional</td>
              <td className="p-3 text-sm border">{(survey.fireAlarm.Repeater_Panel?.Conventional ?? 'NA')}</td>
              <td className="p-3 text-sm border w-1/5">No. of Zones</td>
              <td className="p-3 text-sm border">{survey.fireAlarm.Repeater_Panel?.No_Of_Zones ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border w-2/5">Make</td>
              <td className="p-3 text-sm border">{survey.fireAlarm.Repeater_Panel?.Make ?? 'NA'}</td>
            </tr>
            <tr className="hover:bg-gray-50">
            <td className="p-3 text-sm border w-2/5">Model No.</td>
            <td className="p-3 text-sm border">{survey.fireAlarm.Repeater_Panel?.Model_No ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
      {['Smoke Detectors', 'Heat Detectors', 'Beam Detectors', 'Multi - Detectors', 'Manual Call Points', 'Hooters', 'Response Indicators', 'Hooter Cum Flasher', 'Control Module'].map((item, index) => (
        <div key={index} className="overflow-x-auto shadow-md rounded-lg">
          <table className="w-full bg-white">
            <tbody>
              <tr className="hover:bg-gray-50">
                <td className="p-3 text-sm border w-3/5" rowSpan={2}>{item}</td>
                <td className="p-3 text-sm border">{survey.fireAlarm?.[item.replace(/\s+/g, '_')] ?? 'NA'}</td>
              </tr>
              <tr className="hover:bg-gray-50"></tr>
            </tbody>
          </table>
        </div>
      ))}

      <div className="col-span-3 bg-gray-100 p-4 rounded-lg italic">
        REMARKS: {survey.fireAlarm?.Remarks ?? 'NA'}
      </div>
    </div>
  </>
) : null}

{survey.firePump ? (
  <>
    <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
      <h3 className="text-2xl font-bold">FIRE PUMP ROOM SYSTEM</h3>
    </div>

    <div className="space-y-6">
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-3 text-sm font-semibold text-left w-1/5" rowSpan={2}>Main Hydrant Pump</th>
              <th className="p-3 text-sm font-semibold text-left">Make</th>
              <th className="p-3 text-sm font-semibold text-left">Model</th>
              <th className="p-3 text-sm font-semibold text-left">Rating</th>
              <th className="p-3 text-sm font-semibold text-left">Serial No</th>
              <th className="p-3 text-sm font-semibold text-left">HP</th>
              <th className="p-3 text-sm font-semibold text-left">Cut In Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Cut Off Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Current</th>
              <th className="p-3 text-sm font-semibold text-left">Voltage</th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border">{survey.firePump?.Main_Hydrant_Pump?.Make ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Hydrant_Pump?.Model ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Hydrant_Pump?.Rating ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Hydrant_Pump?.Serial_No ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Hydrant_Pump?.Hp ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Hydrant_Pump?.Cut_In_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Hydrant_Pump?.Cut_Off_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Hydrant_Pump?.Current ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Hydrant_Pump?.Voltage ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-3 text-sm font-semibold text-left w-1/5" rowSpan={2}>Main Sprinkler Pump</th>
              <th className="p-3 text-sm font-semibold text-left">Make</th>
              <th className="p-3 text-sm font-semibold text-left">Model</th>
              <th className="p-3 text-sm font-semibold text-left">Rating</th>
              <th className="p-3 text-sm font-semibold text-left">Serial No</th>
              <th className="p-3 text-sm font-semibold text-left">HP</th>
              <th className="p-3 text-sm font-semibold text-left">Cut In Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Cut Off Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Current</th>
              <th className="p-3 text-sm font-semibold text-left">Voltage</th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border">{survey.firePump?.Main_Sprinkler_Pump?.Make ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Sprinkler_Pump?.Model ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Sprinkler_Pump?.Rating ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Sprinkler_Pump?.Serial_No ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Sprinkler_Pump?.Hp ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Sprinkler_Pump?.Cut_In_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Sprinkler_Pump?.Cut_Off_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Sprinkler_Pump?.Current ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Main_Sprinkler_Pump?.Voltage ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-3 text-sm font-semibold text-left w-1/5" rowSpan={2}>Hydrant Jockey Pump</th>
              <th className="p-3 text-sm font-semibold text-left">Make</th>
              <th className="p-3 text-sm font-semibold text-left">Model</th>
              <th className="p-3 text-sm font-semibold text-left">Rating</th>
              <th className="p-3 text-sm font-semibold text-left">Serial No</th>
              <th className="p-3 text-sm font-semibold text-left">HP</th>
              <th className="p-3 text-sm font-semibold text-left">Cut In Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Cut Off Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Current</th>
              <th className="p-3 text-sm font-semibold text-left">Voltage</th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border">{survey.firePump?.Hydrant_Jockey_Pump?.Make ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Hydrant_Jockey_Pump?.Model ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Hydrant_Jockey_Pump?.Rating ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Hydrant_Jockey_Pump?.Serial_No ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Hydrant_Jockey_Pump?.Hp ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Hydrant_Jockey_Pump?.Cut_In_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Hydrant_Jockey_Pump?.Cut_Off_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Hydrant_Jockey_Pump?.Current ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Hydrant_Jockey_Pump?.Voltage ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-3 text-sm font-semibold text-left w-1/5" rowSpan={2}>Sprinkler Jockey Pump</th>
              <th className="p-3 text-sm font-semibold text-left">Make</th>
              <th className="p-3 text-sm font-semibold text-left">Model</th>
              <th className="p-3 text-sm font-semibold text-left">Rating</th>
              <th className="p-3 text-sm font-semibold text-left">Serial No</th>
              <th className="p-3 text-sm font-semibold text-left">HP</th>
              <th className="p-3 text-sm font-semibold text-left">Cut In Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Cut Off Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Current</th>
              <th className="p-3 text-sm font-semibold text-left">Voltage</th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border">{survey.firePump?.Sprinkler_Jockey_Pump?.Make ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Sprinkler_Jockey_Pump?.Model ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Sprinkler_Jockey_Pump?.Rating ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Sprinkler_Jockey_Pump?.Serial_No ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Sprinkler_Jockey_Pump?.Hp ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Sprinkler_Jockey_Pump?.Cut_In_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Sprinkler_Jockey_Pump?.Cut_Off_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Sprinkler_Jockey_Pump?.Current ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Sprinkler_Jockey_Pump?.Voltage ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-3 text-sm font-semibold text-left w-1/5" rowSpan={2}>Diesel Engine</th>
              <th className="p-3 text-sm font-semibold text-left">Make</th>
              <th className="p-3 text-sm font-semibold text-left">Model</th>
              <th className="p-3 text-sm font-semibold text-left">Rating</th>
              <th className="p-3 text-sm font-semibold text-left">Serial No</th>
              <th className="p-3 text-sm font-semibold text-left">HP</th>
              <th className="p-3 text-sm font-semibold text-left">Cut In Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Cut Off Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Current</th>
              <th className="p-3 text-sm font-semibold text-left">Voltage</th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border">{survey.firePump?.Diesel_Engine?.Make ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Diesel_Engine?.Model ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Diesel_Engine?.Rating ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Diesel_Engine?.Serial_No ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Diesel_Engine?.Hp ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Diesel_Engine?.Cut_In_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Diesel_Engine?.Cut_Off_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Diesel_Engine?.Current ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Diesel_Engine?.Voltage ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-3 text-sm font-semibold text-left w-1/5" rowSpan={2}>Bo

oster Pump</th>
              <th className="p-3 text-sm font-semibold text-left">Make</th>
              <th className="p-3 text-sm font-semibold text-left">Model</th>
              <th className="p-3 text-sm font-semibold text-left">Rating</th>
              <th className="p-3 text-sm font-semibold text-left">Serial No</th>
              <th className="p-3 text-sm font-semibold text-left">HP</th>
              <th className="p-3 text-sm font-semibold text-left">Cut In Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Cut Off Pressure</th>
              <th className="p-3 text-sm font-semibold text-left">Current</th>
              <th className="p-3 text-sm font-semibold text-left">Voltage</th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border">{survey.firePump?.Booster_Pump?.Make ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Booster_Pump?.Model ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Booster_Pump?.Rating ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Booster_Pump?.Serial_No ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Booster_Pump?.Hp ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Booster_Pump?.Cut_In_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Booster_Pump?.Cut_Off_Pressure ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Booster_Pump?.Current ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Booster_Pump?.Voltage ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg mt-6">
        <table className="w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-3 text-sm font-semibold text-left w-1/5">Control Panel</th>
              <th className="p-3 text-sm font-semibold text-left">Hydrant Pump</th>
              <th className="p-3 text-sm font-semibold text-left">Jockey - 1</th>
              <th className="p-3 text-sm font-semibold text-left">Sprinkler Pump</th>
              <th className="p-3 text-sm font-semibold text-left">Jockey - 2</th>
              <th className="p-3 text-sm font-semibold text-left">Diesel Engine</th>
              <th className="p-3 text-sm font-semibold text-left">Booster -1</th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="p-3 text-sm border">Auto / Manual</td>
              <td className="p-3 text-sm border">{survey.firePump?.Control_Panel?.Hydrant_Pump ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Control_Panel?.Jockey_1 ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Control_Panel?.Sprinkler_Pump ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Control_Panel?.Jockey_2 ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Control_Panel?.Diesel_Engine ?? 'NA'}</td>
              <td className="p-3 text-sm border">{survey.firePump?.Control_Panel?.Booster_One ?? 'NA'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <div className="bg-gray-100 p-4 rounded-lg italic mt-6">
      Remarks: {survey.firePump?.Remarks ?? 'NA'}
    </div>
  </>
) : null}

<div className="flex justify-center mt-8">
  <DownloadButton 
    reportId={survey.report_id} 
    clientName={survey.customerDetails.client_name} 
    type="basic_survey" 
  />
</div>

    </div>
  );
};

export default BasicSurvey;
