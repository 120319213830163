import React from "react";
import { useLocation } from "react-router-dom";
import CustomerDetails from "./customerDetails";
import Letterhead from "../CommonFormComponents/letterHead/letterHead";
import DownloadButton from "../CommonFormComponents/DownloadButton/downloadButton";

const GasLeakDetectionSystem = () => {
  const location = useLocation();
  const { survey } = location.state || {};
  const gasLeakDetector = survey?.system;

  if (!gasLeakDetector) {
    return <div>No Gas Leak Detector data available.</div>;
  }

  // Define the initial table rows
  const tableRows = [
    { label: "No. of Areas Covered:", value: gasLeakDetector.No_Of_Areas_Covered || "N/A" },
    { label: "No. of Gas Banks:", value: gasLeakDetector.No_Of_Banks || "N/A" },
    { label: "Length of Gas Bank:", value: gasLeakDetector.Length || "N/A" },
    { label: "Width of Gas Bank:", value: gasLeakDetector.Width || "N/A" },
    { label: "Type of Gas:", value: gasLeakDetector.Type || "N/A" },
    { label: "Make of System:", value: gasLeakDetector.Make || "N/A" },
    { label: "No. of Detectors:", value: gasLeakDetector.No_Of_Detectors || "N/A" },
    { label: "Auto Shut-off of Gas Line:", value: gasLeakDetector.Auto_Shut_Off || "N/A" },
    { label: "Working Condition:", value: gasLeakDetector.Working_Cond || "N/A" },
    { label: "Reason:", value: gasLeakDetector.Reason || "N/A" },
  ];

  // Check if new system data is present and add the third column accordingly
  if (gasLeakDetector?.New_System === "Yes") {
    tableRows[0].newValue = "----";
    tableRows[1].newValue = gasLeakDetector?.New_No_Of_Banks || "N/A";
    tableRows[2].newValue = gasLeakDetector?.New_Length || "N/A";
    tableRows[3].newValue = gasLeakDetector?.New_Width || "N/A";
    tableRows[4].newValue = gasLeakDetector?.New_Type || "N/A";
    tableRows[5].newValue = "----";
    tableRows[6].newValue = gasLeakDetector?.New_No_Of_Detectors || "N/A";
    tableRows[7].newValue = gasLeakDetector?.New_Auto_Shut_Off || "N/A";
    tableRows[8].newValue = "----";
    tableRows[9].newValue = "----";
  }

  // Define column headers based on the new system data
  const headers = gasLeakDetector?.New_System === "Yes" ? ["", "Details", "New System Recommendations"] : ["", "Details"];

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
      <Letterhead />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
        <h3 className="text-2xl font-bold">Survey Report</h3>
      </div>

      <CustomerDetails survey={survey} />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
        <h3 className="text-2xl font-bold">Gas aLeak Detection System</h3>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg mb-6">
        <table className="w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableRows.map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="p-3 text-sm border font-bold">{row.label}</td>
                <td className="p-3 text-sm border">{row.value}</td>
                {gasLeakDetector?.New_System === "Yes" && (
                  <td className="p-3 text-sm border">{row.newValue}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center">
        <DownloadButton
          reportId={survey.report_id}
          clientName={survey.customerDetails?.client_name}
          type="advanced_survey"
        />
      </div>
    </div>
  );
};

export default GasLeakDetectionSystem;
