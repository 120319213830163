import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOut } from 'lucide-react';

function Logout({ setIsLoggedIn, isSidebarOpen }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to log out?');

    if (confirmLogout) {
      localStorage.removeItem('token');
      setIsLoggedIn(false);
      navigate('/');
    }
  };

  return (
    <button 
      onClick={handleLogout} 
      className={`flex items-center px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-300 ${
        isSidebarOpen ? 'w-full' : 'w-auto'
      }`}
    >
      <LogOut size={18} className={isSidebarOpen ? 'mr-2' : ''} />
      {(isSidebarOpen) && 'Logout'}
    </button>
  );
}

export default Logout;