import React from "react";
import { useLocation } from "react-router-dom";
import CustomerDetails from "./customerDetails";
import Letterhead from "../CommonFormComponents/letterHead/letterHead";
import DownloadButton from "../CommonFormComponents/DownloadButton/downloadButton";

const FireSafetyDoors = () => {
  const location = useLocation();
  const { survey } = location.state || {};
  const fireSafetyDoors = survey.system;

  if (!fireSafetyDoors) {
    return <div>No Fire Safety Door data available.</div>;
  }

  // Define the initial table rows
  const tableRows = [
    { label: "Length of Fire Safety Door:", value: fireSafetyDoors?.Length || "N/A" },
    { label: "Width of Fire Safety Door:", value: fireSafetyDoors?.Width || "N/A" },
    { label: "Integration with Fire Alarm System:", value: fireSafetyDoors?.Int_Done_With_Fas || "N/A" },
    { label: "Integration with Pressurized Staircase:", value: fireSafetyDoors?.Int_Done_With_Pst || "N/A" },
    { label: "Integration with Pressurized System:", value: fireSafetyDoors?.Int_Done_With_Psy || "N/A" },
    { label: "Integration with ACS:", value: fireSafetyDoors?.Int_Done_With_Acs || "N/A" },
    { label: "Number of Doors:", value: fireSafetyDoors?.No_Of_Doors || "N/A" },
    { label: "Number of Push Bars:", value: fireSafetyDoors?.No_Of_Push_Bars || "N/A" },
    { label: "Push Bar Present:", value: fireSafetyDoors?.Push_Bar_Present || "N/A" },
    { label: "Doors in Working Condition:", value: fireSafetyDoors?.Working_Cond || "N/A" },
    { label: "Missing Part:", value: fireSafetyDoors?.Missing_Part || "N/A" }
  ];

  // Check if new system data is present and add the third column accordingly
  if (fireSafetyDoors?.New_System === "Yes") {
    tableRows[0].newValue = fireSafetyDoors?.New_Length || "N/A";
    tableRows[1].newValue = fireSafetyDoors?.New_Width || "N/A";
    tableRows[2].newValue = "----";
    tableRows[3].newValue = "----";
    tableRows[4].newValue = "----";
    tableRows[5].newValue = "----";
    tableRows[6].newValue = fireSafetyDoors?.New_No_Of_Doors || "N/A";
    tableRows[7].newValue = "----";
    tableRows[8].newValue = fireSafetyDoors?.New_No_Of_Push_Bars || "N/A";
    tableRows[9].newValue = "----";
    tableRows[10].newValue = "----";
  }

  // Define column headers based on the new system data
  const headers = fireSafetyDoors?.New_System === "Yes" ? ["", "Details", "New System Recommendations"] : ["", "Details"];

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
      <Letterhead />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
        <h3 className="text-2xl font-bold">Survey Report</h3>
      </div>

      <CustomerDetails survey={survey} />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
        <h3 className="text-2xl font-bold">Fire Safety Doors</h3>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg mb-6">
        <table className="w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {tableRows.map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="p-3 text-sm border font-bold">{row.label}</td>
                <td className="p-3 text-sm border">{row.value}</td>
                {fireSafetyDoors?.New_System === "Yes" && (
                  <td className="p-3 text-sm border">{row.newValue}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center">
        <DownloadButton
          reportId={survey.report_id}
          clientName={survey.customerDetails?.client_name}
          type="advanced_survey"
        />
      </div>
    </div>
  );
};

export default FireSafetyDoors;
