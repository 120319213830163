import React from "react";
import { useLocation } from "react-router-dom";
import CustomerDetails from "./customerDetails";
import Letterhead from "../CommonFormComponents/letterHead/letterHead";
import DownloadButton from "../CommonFormComponents/DownloadButton/downloadButton";

const TubeSuppressionSystem = () => {
  const location = useLocation();
  const { survey } = location.state || {};
  const tubeSuppression = survey?.system;

  if (!tubeSuppression) {
    return <div>No Tube Based Suppression System data available.</div>;
  }

  // Define the initial table rows for the Tube Based Suppression System
  const tableRows = [
    { label: "Application Area:", value: tubeSuppression.App_Area || "N/A" },
    { label: "Panel Height:", value: tubeSuppression.Panel_Height || "N/A" },
    { label: "Panel Width:", value: tubeSuppression.Panel_Width || "N/A" },
    { label: "Panel Depth:", value: tubeSuppression.Panel_Depth || "N/A" },
    { label: "Number of Compartments:", value: tubeSuppression.No_Of_Compartments || "N/A" },
    { label: "Largest Compartment (Height):", value: tubeSuppression.Largest_Comp_Height || "N/A" },
    { label: "Largest Compartment (Width):", value: tubeSuppression.Largest_Comp_Width || "N/A" },
    { label: "Largest Compartment (Depth):", value: tubeSuppression.Largest_Comp_Depth || "N/A" },
    { label: "Working Condition:", value: tubeSuppression.Working_Cond || "N/A" },
    { label: "Reason for Non-Operation (if any):", value: tubeSuppression.Reason || "N/A" },
    { label: "Refilling Required:", value: tubeSuppression.Refilling_Req || "N/A" },
    { label: "Last Refilled Date:", value: tubeSuppression.Last_Refilled_Date || "N/A" },
    { label: "Next Refill Date:", value: tubeSuppression.Next_Refill_Date || "N/A" },
    { label: "Cylinder Capacity:", value: tubeSuppression.Cylinder_Capacity || "N/A" },
    { label: "Make:", value: tubeSuppression.Make || "N/A" },
    { label: "Type:", value: tubeSuppression.Type || "N/A" },
    { label: "Type of Gas:", value: tubeSuppression.Type_Of_Gas || "N/A" }
  ];

  // Check if new system data is present and add the third column accordingly
  if (tubeSuppression?.New_System === "Yes") {
    tableRows[0].newValue = "----";
    tableRows[1].newValue = tubeSuppression?.New_Panel_Height || "N/A";
    tableRows[2].newValue = tubeSuppression?.New_Panel_Width || "N/A";
    tableRows[3].newValue = tubeSuppression?.New_Panel_Depth || "N/A";
    tableRows[4].newValue = "----";
    tableRows[5].newValue = "----";
    tableRows[6].newValue = "----";
    tableRows[7].newValue = "----";
    tableRows[8].newValue = "----";
    tableRows[9].newValue = "----";
    tableRows[10].newValue = "----";
    tableRows[11].newValue = "----";
    tableRows[12].newValue = "----";
    tableRows[13].newValue = tubeSuppression?.New_Cylinder_Capacity || "N/A";
    tableRows[14].newValue = "----";
    tableRows[15].newValue = tubeSuppression?.New_Type || "N/A";
    tableRows[16].newValue = tubeSuppression?.New_Type_Of_Gas || "N/A";
  }

  // Define column headers based on the new system data
  const headers = tubeSuppression?.New_System === "Yes" ? ["", "Details", "New System Recommendations"] : ["", "Details"];

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
      <Letterhead />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
        <h3 className="text-2xl font-bold">Survey Report</h3>
      </div>

      <CustomerDetails survey={survey} />

      <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
        <h3 className="text-2xl font-bold">Tube Suppression System</h3>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg mb-6">
        <table className="w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableRows.map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="p-3 text-sm border font-bold">{row.label}</td>
                <td className="p-3 text-sm border">{row.value}</td>
                {tubeSuppression?.New_System === "Yes" && (
                  <td className="p-3 text-sm border">{row.newValue}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center">
        <DownloadButton
          reportId={survey.report_id}
          clientName={survey.customerDetails?.client_name}
          type="advanced_survey"
        />
      </div>
    </div>
  );
};

export default TubeSuppressionSystem;
