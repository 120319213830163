import React from "react";
import { useLocation } from 'react-router-dom';
import Letterhead from "../../CommonFormComponents/letterHead/letterHead";
import DownloadButton from "../../CommonFormComponents/DownloadButton/downloadButton";
import styles from './FormB.module.css';

const FormB = () => {
    const location = useLocation();
    const { survey } = location.state || {};

    if (!survey) {
        return null;
    }

    return (
        <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
            <Letterhead />

            <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
                <h3 className="text-2xl font-bold">FORM B AUDIT CHECK LIST</h3>
            </div>

            <div className="space-y-2 mb-4">
                <p>Name: {survey.customerDetails.client_name}</p>
                <p>Address Of Building: {survey.customerDetails.address}</p>
                <p>DATE: {survey.date}</p>
                <p><span>Report No: {survey.report_id}</span></p>
            </div>

            <div className="overflow-x-auto shadow-md rounded-lg mb-6">
                <table className="w-full bg-white">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                            <th className="p-3 text-sm font-semibold text-left">Particulars</th>
                            <th className="p-3 text-sm font-semibold text-left">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="hover:bg-gray-50">
                            <td className="p-3 text-sm border">1</td>
                            <td className="p-3 text-sm border">Type of Occupancy</td>
                            <td className="p-3 text-sm border">{survey.customerDetails?.occupancy ?? 'NA'}</td>
                        </tr>
                        <tr className="hover:bg-gray-50">
                            <td className="p-3 text-sm border">2</td>
                            <td className="p-3 text-sm border">Contact Details of Building Incharge</td>
                            <td className="p-3 text-sm border">
                                <p>Name: {survey.customerDetails.Contact_Details_Incharge?.Name ?? 'NA'}</p>
                                <p>Tel. No: {survey.customerDetails.Contact_Details_Incharge?.Tel_No ?? 'NA'}</p>
                                <p>Email ID: {survey.customerDetails.Contact_Details_Incharge?.Email_Id ?? 'NA'}</p>
                            </td>
                        </tr>
                        <tr className="hover:bg-gray-50">
                            <td className="p-3 text-sm border">3</td>
                            <td className="p-3 text-sm border">Details Of Fire NOC / OC</td>
                            <td className="p-3 text-sm border">
                                <p>Issuing Authority: {survey.customerDetails.Details_Fire_NOC?.Issuing_Authority ?? 'NA'}</p>
                                <p>Letter. No: {survey.customerDetails.Details_Fire_NOC?.Letter_No ?? 'NA'}</p>
                                <p>Date: {survey.customerDetails.Details_Fire_NOC?.Date ?? 'NA'}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="flex flex-col md:flex-row gap-6">
                <div className="w-full md:w-1/2 space-y-6 mb-6">
                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <div className="bg-yellow-300 p-4">
                            <h3 className="text-xl font-bold">A. Fire Extinguishers</h3>
                        </div>
                        <table className="w-full">
                            <tbody>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">A1</td>
                                    <td className="p-3 text-sm border">ABC</td>
                                    <td className="p-3 text-sm border">{survey.fireExtinguishers?.ABC ? `${survey.fireExtinguishers.ABC} nos.` : 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">A2</td>
                                    <td className="p-3 text-sm border">CO2</td>
                                    <td className="p-3 text-sm border">{survey.fireExtinguishers?.CO2 ? `${survey.fireExtinguishers.CO2} nos.` : 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">A3</td>
                                    <td className="p-3 text-sm border">Foam</td>
                                    <td className="p-3 text-sm border">{survey.fireExtinguishers?.Foam ? `${survey.fireExtinguishers.Foam} nos.` : 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">A4</td>
                                    <td className="p-3 text-sm border">Water</td>
                                    <td className="p-3 text-sm border">{survey.fireExtinguishers?.Water ? `${survey.fireExtinguishers.Water} nos.` : 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">A5</td>
                                    <td className="p-3 text-sm border">Others</td>
                                    <td className="p-3 text-sm border">{survey.fireExtinguishers?.Others ? `${survey.fireExtinguishers.Others} nos.` : 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">A6</td>
                                    <td className="p-3 text-sm border">Are they easily accessible?</td>
                                    <td className="p-3 text-sm border">{survey.fireExtinguishers?.Easily_Accessible ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">A7</td>
                                    <td className="p-3 text-sm border">Are there enough fire extinguishers installed?</td>
                                    <td className="p-3 text-sm border">{survey.fireExtinguishers?.Enough_Ext_Installed ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">A8</td>
                                    <td className="p-3 text-sm border">Are all extinguishers properly maintained?</td>
                                    <td className="p-3 text-sm border">{survey.fireExtinguishers?.Ext_Properly_Maintained ?? 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <div className="bg-yellow-300 p-4">
                            <h3 className="text-xl font-bold">C. Hydrant and Sprinkler System</h3>
                        </div>
                        <table className="w-full">
                            <tbody>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C1</td>
                                    <td className="p-3 text-sm border">Is there a hydrant / riser system installed?</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Hydrant_Riser_Installed ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C2</td>
                                    <td className="p-3 text-sm border">No. of courtyard hydrants installed</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Num_CourtYard_Hydrant ? `${survey.fireHydrant.Num_CourtYard_Hydrant} Nos.` : 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C3</td>
                                    <td className="p-3 text-sm border">No. of Internal Hydrants Installed.</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Num_Internal_Hydrants ? `${survey.fireHydrant.Num_Internal_Hydrants} Nos.` : 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C4</td>
                                    <td className="p-3 text-sm border">No. of Hose Reels Installed</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Num_Hose_Reel ? `${survey.fireHydrant.Num_Hose_Reel} Nos.` : 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C5</td>
                                    <td className="p-3 text-sm border">Is Pressure at remotest point more than 3.2 Bar?</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Pressure_More_Than_32 ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C6</td>
                                    <td className="p-3 text-sm border">Is the sprinkler system installed?</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Sprinkler_Installed ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C7</td>
                                    <td className="p-3 text-sm border">No. of Sprinklers Installed</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Num_Sprinkler_Installed ? `${survey.fireHydrant.Num_Sprinkler_Installed} Nos.` : 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C8</td>
                                    <td className="p-3 text-sm border">No. of Alarm Valves Installed</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Num_Alarm_Installed ? `${survey.fireHydrant.Num_Alarm_Installed} Nos.` : 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C9</td>
                                    <td className="p-3 text-sm border">Flow Switches Installed?</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Flow_Switches_Installed ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C10</td>
                                    <td className="p-3 text-sm border">Are all the above in operating condition?</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.All_In_Operating_Cond ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C11</td>
                                    <td className="p-3 text-sm border">Are there any leakages in the Piping System?</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Any_Leakages ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">C12</td>
                                    <td className="p-3 text-sm border">If yes above, give details:</td>
                                    <td className="p-3 text-sm border">{survey.fireHydrant?.Details ?? 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
                        <div className="bg-yellow-300 p-4">
                            <h3 className="text-xl font-bold">D. Passive Protection System</h3>
                        </div>
                        <table className="w-full">
                            <tbody>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">D1</td>
                                    <td className="p-3 text-sm border">Is Fire Evacuation Plan Installed?</td>
                                    <td className="p-3 text-sm border">{survey.passiveProtection?.Fire_Evacuation_Plan ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">D2</td>
                                    <td className="p-3 text-sm border">Is Fire Emergency Instruction Board Installed?</td>
                                    <td className="p-3 text-sm border">{survey.passiveProtection?.Emergency_Board ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">D3</td>
                                    <td className="p-3 text-sm border">Are adequate Signages provided?</td>
                                    <td className="p-3 text-sm border">
                                        <p>Fire Exit: {survey.passiveProtection?.Adequate_Signages?.Fire_Exit ?? 'N/A'}</p>
                                        <p>Fire Extinguisher: {survey.passiveProtection?.Adequate_Signages?.Fire_Extinguisher ?? 'N/A'}</p>
                                        <p>MCP: {survey.passiveProtection?.Adequate_Signages?.MCP ?? 'N/A'}</p>
                                        <p>Refuge Area: {survey.passiveProtection?.Adequate_Signages?.Refuge_Area ?? 'N/A'}</p>
                                        <p>Hose Reel: {survey.passiveProtection?.Adequate_Signages?.Hose_Reel ?? 'N/A'}</p>
                                    </td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">D4</td>
                                    <td className="p-3 text-sm border">Does Emergency Lighting & Fire Pump System have Emergency Back-up?</td>
                                    <td className="p-3 text-sm border">{survey.passiveProtection?.Emerygency_Backup ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">D5</td>
                                    <td className="p-3 text-sm border">Location of assembly point identified?</td>
                                    <td className="p-3 text-sm border">{survey.passiveProtection?.Location_Assembly_Point ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">D6</td>
                                    <td className="p-3 text-sm border">Is assembly Point marked Clearly:</td>
                                    <td className="p-3 text-sm border">{survey.passiveProtection?.Assembly_Point_Marked ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">D7</td>
                                    <td className="p-3 text-sm border">Is Fire Lift Provided?</td>
                                    <td className="p-3 text-sm border">{survey.passiveProtection?.Fire_Lift_Provided ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">D8</td>
                                    <td className="p-3 text-sm border">Do all Lifts have a Valid Licence to operate?</td>
                                    <td className="p-3 text-sm border">{survey.passiveProtection?.Valid_Licence ?? 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="w-full md:w-1/2 space-y-6 mb-6">
                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <div className="bg-yellow-300 p-4">
                            <h3 className="text-xl font-bold">B. Fire Tanks and Pumps</h3>
                        </div>
                        <table className="w-full">
                            <tbody>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">B1</td>
                                    <td className="p-3 text-sm border">
                                        <h4 className="font-bold">Fire Water Tanks: </h4>
                                        <p>Capacity of Underground Tank</p>
                                        <p>Capacity of Overhead Tank</p>
                                    </td>
                                    <td className="p-3 text-sm border">
                                        <h4 className="font-bold text-white">__</h4>
                                        <p>{survey.firePump?.Fire_Water_Tanks?.Cap_Underground_Tank ? `${survey.firePump.Fire_Water_Tanks.Cap_Underground_Tank} Liters` : 'N/A'}</p>
                                        <p>{survey.firePump?.Fire_Water_Tanks?.Cap_Overhead_Tank ? `${survey.firePump.Fire_Water_Tanks.Cap_Overhead_Tank} Liters` : 'N/A'}</p>
                                    </td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">B2</td>
                                    <td className="p-3 text-sm border">
                                        <h4 className="font-bold">Main Hydrant Pumps</h4>
                                        <p>Mode of Operation: {survey.firePump?.Main_Hydrant_Pump?.Mode_Operation ?? 'N/A'}</p>
                                        <p>Run Test Done? {survey.firePump?.Main_Hydrant_Pump?.Run_Test_Done ?? 'N/A'}</p>
                                    </td>
                                    <td className="p-3 text-sm border">
                                        <p>Make: {survey.firePump?.Main_Hydrant_Pump?.Make ?? 'N/A'}</p>
                                        <p>Flow: {survey.firePump?.Main_Hydrant_Pump?.Flow ? `${survey.firePump.Main_Hydrant_Pump.Flow} m³/h` : 'N/A'}</p>
                                        <p>Head: {survey.firePump?.Main_Hydrant_Pump?.Head ? `${survey.firePump.Main_Hydrant_Pump.Head} m` : 'N/A'}</p>
                                        <p>Power: {survey.firePump?.Main_Hydrant_Pump?.Power ? `${survey.firePump.Main_Hydrant_Pump.Power} hp` : 'N/A'}</p>
                                    </td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">B3</td>
                                    <td className="p-3 text-sm border">
                                        <h4 className="font-bold">Hydrant Jockey Pump</h4>
                                        <p>Mode of Operation: {survey.firePump?.Hydrant_Jockey_Pump?.Mode_Operation ?? 'N/A'}</p>
                                        <p>Run Test Done? {survey.firePump?.Hydrant_Jockey_Pump?.Run_Test_Done ?? 'N/A'}</p>
                                    </td>
                                    <td className="p-3 text-sm border">
                                        <p>Make: {survey.firePump?.Hydrant_Jockey_Pump?.Make ?? 'N/A'}</p>
                                        <p>Flow: {survey.firePump?.Hydrant_Jockey_Pump?.Flow ? `${survey.firePump.Hydrant_Jockey_Pump.Flow} m³/h` : 'N/A'}</p>
                                        <p>Head: {survey.firePump?.Hydrant_Jockey_Pump?.Head ? `${survey.firePump.Hydrant_Jockey_Pump.Head} m` : 'N/A'}</p>
                                        <p>Power: {survey.firePump?.Hydrant_Jockey_Pump?.Power ? `${survey.firePump.Hydrant_Jockey_Pump.Power} hp` : 'N/A'}</p>
                                    </td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">B4</td>
                                    <td className="p-3 text-sm border">
                                        <h4 className="font-bold">Main Sprinkler Pump</h4>
                                        <p>Mode of Operation: {survey.firePump?.Main_Sprinkler_Pump?.Mode_Operation ?? 'N/A'}</p>
                                        <p>Run Test Done? {survey.firePump?.Main_Sprinkler_Pump?.Run_Test_Done ?? 'N/A'}</p>
                                    </td>
                                    <td className="p-3 text-sm border">
                                        <p>Make: {survey.firePump?.Main_Sprinkler_Pump?.Make ?? 'N/A'}</p>
                                        <p>Flow: {survey.firePump?.Main_Sprinkler_Pump?.Flow ? `${survey.firePump.Main_Sprinkler_Pump.Flow} m³/h` : 'N/A'}</p>
                                        <p>Head: {survey.firePump?.Main_Sprinkler_Pump?.Head ? `${survey.firePump.Main_Sprinkler_Pump.Head} m` : 'N/A'}</p>
                                        <p>Power: {survey.firePump?.Main_Sprinkler_Pump?.Power ? `${survey.firePump.Main_Sprinkler_Pump.Power} hp` : 'N/A'}</p>
                                    </td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">B5</td>
                                    <td className="p-3 text-sm border">
                                        <h4 className="font-bold">Sprinkler Jockey Pumps</h4>
                                        <p>Mode of Operation: {survey.firePump?.Sprinkler_Jockey_Pump?.Mode_Operation ?? 'N/A'}</p>
                                        <p>Run Test Done? {survey.firePump?.Sprinkler_Jockey_Pump?.Run_Test_Done ?? 'N/A'}</p>
                                    </td>
                                    <td className="p-3 text-sm border">
                                        <p>Make: {survey.firePump?.Sprinkler_Jockey_Pump?.Make ?? 'N/A'}</p>
                                        <p>Flow: {survey.firePump?.Sprinkler_Jockey_Pump?.Flow ? `${survey.firePump.Sprinkler_Jockey_Pump.Flow} m³/h` : 'N/A'}</p>
                                        <p>Head: {survey.firePump?.Sprinkler_Jockey_Pump?.Head ? `${survey.firePump.Sprinkler_Jockey_Pump.Head} m` : 'N/A'}</p>
                                        <p>Power: {survey.firePump?.Sprinkler_Jockey_Pump?.Power ? `${survey.firePump.Sprinkler_Jockey_Pump.Power} hp` : 'N/A'}</p>
                                    </td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">B6</td>
                                    <td className="p-3 text-sm border">
                                        <h4 className="font-bold">Diesel Engine Pumps</h4>
                                        <p>Mode of Operation: {survey.firePump?.Diesel_Engine_Pump?.Mode_Operation ?? 'N/A'}</p>
                                        <p>Run Test Done? {survey.firePump?.Diesel_Engine_Pump?.Run_Test_Done ?? 'N/A'}</p>
                                    </td>
                                    <td className="p-3 text-sm border">
                                        <p>Make: {survey.firePump?.Diesel_Engine_Pump?.Make ?? 'N/A'}</p>
                                        <p>Flow: {survey.firePump?.Diesel_Engine_Pump?.Flow ? `${survey.firePump.Diesel_Engine_Pump.Flow} m³/h` : 'N/A'}</p>
                                        <p>Head: {survey.firePump?.Diesel_Engine_Pump?.Head ? `${survey.firePump.Diesel_Engine_Pump.Head} m` : 'N/A'}</p>
                                        <p>Power: {survey.firePump?.Diesel_Engine_Pump?.Power ? `${survey.firePump.Diesel_Engine_Pump.Power} hp` : 'N/A'}</p>
                                    </td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">B7</td>
                                    <td className="p-3 text-sm border">
                                        <h4 className="font-bold">Booster Pump</h4>
                                        <p>Mode of Operation: {survey.firePump?.Booster_Pump?.Mode_Operation ?? 'N/A'}</p>
                                        <p>Run Test Done? {survey.firePump?.Booster_Pump?.Run_Test_Done ?? 'N/A'}</p>
                                    </td>
                                    <td className="p-3 text-sm border">
                                        <p>Make: {survey.firePump?.Booster_Pump?.Make ?? 'N/A'}</p>
                                        <p>Flow: {survey.firePump?.Booster_Pump?.Flow ? `${survey.firePump.Booster_Pump.Flow} m³/h` : 'N/A'}</p>
                                        <p>Head: {survey.firePump?.Booster_Pump?.Head ? `${survey.firePump.Booster_Pump.Head} m` : 'N/A'}</p>
                                        <p>Power: {survey.firePump?.Booster_Pump?.Power ? `${survey.firePump.Booster_Pump.Power} hp` : 'N/A'}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <div className="bg-yellow-300 p-4">
                            <h3 className="text-xl font-bold">E. Fire Detection / Alarm System</h3>
                        </div>
                        <table className="w-full">
                            <tbody>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E1</td>
                                    <td className="p-3 text-sm border">Is Fire Detection included in this Audit?</td>
                                    <td className="p-3 text-sm border">{survey.fireAlarm?.Fire_Detection_Audit ?? 'N/A'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E2</td>
                                    <td className="p-3 text-sm border">Type of fire alarm system:</td>
                                    <td className="p-3 text-sm border">{survey.fireAlarm?.Fire_Alarm ?? 'NA'}</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E3</td>
                                    <td className="p-3 text-sm border">Location of fire alarm panel & repeater panel:</td>
                                    <td className="p-3 text-sm border">{ survey.fireAlarm?.Location_Alarm_Repeater ?? 'N/A' }</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E4</td>
                                    <td className="p-3 text-sm border">No. of detectors</td>
                                    <td className="p-3 text-sm border">{ survey.fireAlarm?.Num_Detectors ? `${survey.fireAlarm.Num_Detectors} nos.` : 'N/A' }</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E5</td>
                                    <td className="p-3 text-sm border">No. of Hooters</td>
                                    <td className="p-3 text-sm border">{ survey.fireAlarm?.Num_Hooters ? `${survey.fireAlarm.Num_Hooters} nos.` : 'N/A' }</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E6</td>
                                    <td className="p-3 text-sm border">No. of MCP</td>
                                    <td className="p-3 text-sm border">{ survey.fireAlarm?.Num_MCP ? `${survey.fireAlarm.Num_MCP} nos.` : 'N/A' }</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E7</td>
                                    <td className="p-3 text-sm border">Is PA system installed?</td>
                                    <td className="p-3 text-sm border">{ survey.fireAlarm?.PA_Installed ?? 'N/A' }</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E8</td>
                                    <td className="p-3 text-sm border">Smoke Test Done?</td>
                                    <td className="p-3 text-sm border">{ survey.fireAlarm?.Smoke_Test ?? 'N/A' }</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E9</td>
                                    <td className="p-3 text-sm border">MCP Pull Test Done?</td>
                                    <td className="p-3 text-sm border">{ survey.fireAlarm?.MCP_Pull_Test ?? 'N/A' }</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E11</td>
                                    <td className="p-3 text-sm border">Is the FAS System in working condition?</td>
                                    <td className="p-3 text-sm border">{ survey.fireAlarm?.Fas_System_Working ?? 'N/A' }</td>
                                </tr>
                                <tr className="hover:bg-gray-50">
                                    <td className="p-3 text-sm border">E12</td>
                                    <td className="p-3 text-sm border">If no above, give details:</td>
                                    <td className="p-3 text-sm border">{ survey.fireAlarm?.Details ?? 'N/A' }</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                <h3 className="text-2xl font-bold mb-4">Remarks / Recommendation</h3>
                <p className="text-gray-700">Here comes the data</p>
            </div>

            <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
                <table className="w-full">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="p-3 text-sm font-semibold text-left">Form B</th>
                            <th className="p-3 text-sm font-semibold text-left">To Be Issued</th>
                            <th className="p-3 text-sm font-semibold text-left">Compliance Needed</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="hover:bg-gray-50">
                            <td className="p-3 text-sm border">Hydrant and Sprinkler System</td>
                            <td className="p-3 text-sm border">{survey.formB?.Hydrant_Sprinkler_System?.To_Be_Issued ?? 'N/A'}</td>
                            <td className="p-3 text-sm border">{survey.formB?.Hydrant_Sprinkler_System?.Compliance_Needed ?? 'N/A'}</td>
                        </tr>
                        <tr className="hover:bg-gray-50">
                            <td className="p-3 text-sm border">Fire Detection System</td>
                            <td className="p-3 text-sm border">{survey.formB?.Fire_Detection_System?.To_Be_Issued ?? 'N/A'}</td>
                            <td className="p-3 text-sm border">{survey.formB?.Fire_Detection_System?.Compliance_Needed ?? 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>

                <div className="p-6 bg-gray-100">
                    <h3 className="text-xl font-bold mb-2">Remarks</h3>
                    <p className="text-gray-700">{survey.formB?.Remarks ?? 'N/A'}</p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-6 mb-6">
                <div className="w-full md:w-1/2 bg-white shadow-md rounded-lg p-6">
                    <p className="font-bold mb-2">Declaration:</p>
                    <p className="text-sm text-gray-700 mb-4">The inspection has been done based on the information provided by the client. Although care has been taken to inspect the premises thoroughly, in case of wrong/misleading/incomplete disclosure by client, we shall not be responsible for the same.</p>
                    <p className="font-bold mb-2">Checked and verified:</p>
                    <div className="border border-gray-300 p-4 rounded">
                        <p className="mb-2">Signature of Inspecting Officer</p>
                        <p>Name: </p>
                    </div>
                </div>

                <div className="w-full md:w-1/2 bg-white shadow-md rounded-lg p-6">
                    <p className="font-bold mb-2">Declaration:</p>
                    <p className="text-sm text-gray-700 mb-4">The inspection has been done in my presence and I agree with the documented observations thereof. I also undertake to expeditiously rectify / repair / renovate the fire systems, wherever necessary.</p>
                    <p className="font-bold mb-2">Checked and verified:</p>
                    <div className="border border-gray-300 p-4 rounded">
                        <p className="mb-2">Signature & Seal of Client</p>
                        <p>Name: </p>
                    </div>
                </div>
            </div>
            
            <div className="flex justify-center">
                <DownloadButton 
                    reportId={survey.report_id} 
                    clientName={survey.customerDetails.client_name} 
                    type="form_b" 
                />
            </div>
            
        </div>
    );
}

export default FormB;